@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600&display=swap');


.Main{
    background-image: url('../images/Clinic-2.jpg');
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 auto;
    padding: 1rem;
    font-family: 'Raleway', sans-serif;
    
}

.Main{
    color: black;
	min-width: 100%;
	min-height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.row{
    padding: 20px;
}

.app-container{
    padding: 50px;
}

#head{
    margin-top: -50px;
}
#head::after{
    content: '';
    background-color: black;   
    display: block;
    height: 3px;
    width: 300px;
    margin: 12px auto;
}

.Box{
   padding-top: 5%;
}

.Stat{
    padding-top: 2%;

}

.cur-tok, .tot-tok{
    border-top: 1px solid rgb(0, 0, 0);
	font-size: 1.5rem;
    padding-top: 20px;
}

#cur-tok, #tot-tok{
    font-size: 1.5rem;
	padding-top: 0rem;
}