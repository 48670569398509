
  #bg {
    background-image: url('../images/userPage.png');
    background-size: cover;
    padding: 1px;
   }

 /* .Main{
     
    min-width: 100%;
    min-height: 100%;
} */
.user_reg_main {
    margin: 1rem 0 2rem 0;
    min-height: 100vh;
}

.wrapper {
    max-width: 800px;
    margin: 80px auto;
    padding: 30px 40px;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.589);
}

.wrapper label {
    display: block;
    padding-bottom: 0.2rem;
    color: black;
}

.wrapper .form .row {
    padding: 0.15rem 0
}

.wrapper .form .row .form-control, #sub {
    box-shadow: 2px 2px 4px;
}

.wrapper .form .option {
    position: relative;
    padding-left: 20px;
    cursor: pointer
}

.wrapper .form .option input {
    opacity: 0
}

.wrapper .form .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #bbb;
    border-radius: 50%
}

.wrapper .form .option input:checked~.checkmark:after {
    display: block
}

.wrapper .form .option:hover .checkmark {
    background: #f3f3f3
}

.wrapper .form .option .checkmark:after {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    background: linear-gradient(45deg, #ce1e53, #8f00c7);
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 300ms ease-in-out 0s
}

.wrapper .form .option input[type="radio"]:checked~.checkmark {
    /* background: #fff; */
    transition: 300ms ease-in-out 0s
}

.wrapper .form .option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1)
}

#sub {
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    color: #333
}

#sub:focus {
    outline: none
}

@media(max-width: 768.5px) {
    .wrapper {
        margin: 30px
    }

    .wrapper .form .row {
        padding: 0.9rem
    }
}

@media(max-width: 400px) {
    .wrapper {
        padding: 25px;
        margin: 20px
    }
}