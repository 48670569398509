@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600&display=swap");

.Team h1 {
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  color: #333;
  padding-bottom: 10px;
}

.Team h2,
.Team h4 {
  color: black;
  font: caption;
  font-size: larger;
}

.Team h1::after {
  content: "";
  background-color: rgb(156, 156, 156);
  display: block;
  height: 3px;
  width: 300px;
  margin: 12px auto;
}

.size {
  width: 70%;
  height: 70%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile {
  margin-top: 25px;
}

.profile .img-box {
  opacity: 1;
  display: block;
  position: relative;
}

.profile h2 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.profile h4 {
  font-size: 14px;
  margin-top: 0px;
  text-align: center;
}

.img-box::after {
  content: "";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.img-box ul {
  margin: 0;
  padding: 20px 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  display: block;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  white-space: nowrap;
}

.img-box ul li {
  width: 46px;
  height: 45px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 2px;
  padding: 8px;
  display: inline-block;
}

.img-box a {
  color: #fff;
}

.img-box:hover::after {
  opacity: 1;
}

.img-box:hover ul {
  opacity: 1;
}

.img-box a:hover li {
  color: #007bff;
  border: 1px solid #007bff;
}

.img-box::after,
.img-box ul,
.img-box ul li {
  transition: all 0.5s ease-in-out 0s;
}
