
.login-container{
    padding: 81px;
}



.row{
    background: white;
    border-radius: 30px;
}

.image{
    background-image: url('../images/Clinic-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 190px;
}

.login .image{
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
}

.inp{
    height: 50px;
    width: 70%;
    font:caption;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 60px;
    box-shadow: 0px -1px 24px -12px rgba(0, 0, 0, 0.651);
-webkit-box-shadow: 0px -1px 24px -12px rgba(0, 0, 0, 0.616);
-moz-box-shadow: 0px -1px 24px -12px rgba(0, 0, 0, 0.562);
}

.btn1{
    height: 50px;
    width: 38%;
    border: none;
    outline: none;
    border-radius: 60px;
    font-weight: 600;
    font-size: larger;
    background-color: rgb(29, 153, 255);
    color: aliceblue;;
    text-decoration: none;
}
.btn1:hover{
    background: rgb(3, 51, 107);
    transition: 0.3s;
}