html,
body {
  height: 100vh;
}

#image1 {
  background-image: url("../images/clinic1.jpg");
  background-position: center;
  background-size: cover;
  padding: 279px;
  background-color: beige;
  opacity: 50%;
}

#image2 {
  background-image: url("../images/clinic2.jpg");
  background-position: center;
  background-size: cover;
  padding: 279px;
  background-color: beige;
  opacity: 50%;
}

#image3 {
  background-image: url("../images/clinic3.jpg");
  background-position: center;
  background-size: cover;
  padding: 279px;
  background-color: beige;
  opacity: 50%;
}

.custom-carousel-caption {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.bar {
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  .custom-carousel-caption {
    display: block;
    transform: translate(-50%, -80%);
  }

  .bar {
    /* margin-left: 90px; */
    transform: translateX(4%) rotate(90deg);
    margin-bottom: -1px;
    margin-top: -1px;
  }

  .custom-carousel-caption .btn {
    display: block;
    margin-bottom: 5px;
  }
}
